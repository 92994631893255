<template>
  <div class="calendar__milestone"
       :style="[noStyle === true ? {} : { left: `${milestonePosition}px` }]"
       @click="$emit('click', milestone)"
  >
    <div>
      <p>{{ setting.label }}</p>
      <p>{{ humanDate }}</p>
      <p>{{ humanTime }}</p>
    </div>

    <svg viewBox="0 0 50 60" class="svg__background">
      <path d="M1,1 h48 v30 l-24,28 l-24,-28z"/>
    </svg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { milestoneSettingEmpty } from '@/store/modules/Calendar'

export default {
  name: 'Milestone',

  props: {
    firstMilestone: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    milestone: {
      required: true,
      type: Object,
    },
    noStyle: {
      required: false,
      type: Boolean,
    },
    startX: {
      required: true,
      type: Number,
      default: 200,
    },
  },

  data: () => ({
    milestoneDate: new Date(),
    setting: Object.assign({}, milestoneSettingEmpty),
  }),

  computed: {
    ...mapGetters(['milestoneSettings']),
    humanDate () {
      return this.milestone.date !== undefined
             ? this.milestone.date.toLocaleDateString(
              navigator.language || 'fr-FR',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              },
          )
             : ''
    },
    humanTime () {
      return this.milestone.date !== undefined
             ? this.milestone.date.toLocaleTimeString(
              navigator.language || 'fr-FR',
              {
                hour: '2-digit',
                minute: '2-digit',
              },
          )
             : ''
    },
    /**
     * Calculate the milestone cursor position:
     * - 200: legend width
     * - name * 22: day position, 22 = day width
     * - 55: half the width of the cursor
     * - 11: to be on the half of the square of the day
     * - 110 * milestoneNumber: to remove the width of the previous cursor
     *
     * @returns {Number}
     */
    milestonePosition () {
      return this.startX + this.getDayDiff(this.firstMilestone.date, this.milestone.date) * 22 - 55 + 11 - (110 * this.index)
    },
  },

  created () {
    this.setting = {
      ...this.milestoneSettings.find(milestoneSetting => {
        return milestoneSetting.id === this.milestone.milestoneSettingsId
      }),
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.calendar__milestone {
  width: 110px;
  height: 132px;
  position: relative;

  svg.svg__background {
    width: 110px;
    height: 132px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    path {
      fill: transparent;
      stroke: $primaryColor;
      stroke-width: 1;
      cursor: pointer;
    }
  }

  div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45px 30px 1fr;
    height: 100%;
    position: relative;
    z-index: 0;

    p {
      margin: 0;
      text-align: center;
      font-size: 19px;

      &:first-of-type {
        font-weight: bold;
        font-size: 15px;
        line-height: 15px;
        align-self: center;
      }
    }
  }
}
</style>
